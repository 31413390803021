<template>
  <div class="terms">
    <h1>Terms of Use</h1>
    <h4>Effective as of: 19 August 2021</h4>
    <p>
      Thank you for using HinHon. We are very pleased to welcome you here.
      Please read these Terms of Use carefully before accessing or using the
      HinHon Service. This agreement applies to all users and visitors without
      exception. By accessing or using the HinHon Services, you agree to comply
      with this agreement along with other guidelines including our Privacy
      Policy. These Terms of Use may be changed, supplemented, or modified from
      time to time. You agree and accept your responsibility to check these
      Terms of Use periodically.
    </p>
    <h3>Definitions</h3>
    <ul class="number">
      <li>
        "Application" means the relevant mobile application made for download by
        HinHon.
      </li>
      <li>"Account" represents your legal relationship to HinHon.</li>
      <li>
        "HinHon", "We", and "Us" represent the HinHon as well as our licensors,
        affiliates, subsidiaries, agents, and employees.
      </li>
      <li>
        "User", "You", and "Your" refer to the individual person that has
        visited and/or used our Services.
      </li>
      <li>
        "Service" refers to the application, software, products, and services
        provided by HinHon.
      </li>
      <li>
        "Platform" refers to the relevant HinHon application and website that
        enable users to access our Services.
      </li>
      <li>
        "Content" refers to the content and information displayed on the HinHon
        website.
      </li>
      <li>
        "Website" refers to Reyo"s website located at
        <a href="https://hinhon.com">https://hinhon.com</a>, as well as all
        products and services provided by HinHon through the website.
      </li>
      <li>
        "Privacy Policy" means our privacy policy accessible at:
        <a href="https://hinhon.com/privacy-policy"
          >https://hinhon.com/privacy-policy</a
        >
      </li>
      <li>
        "Agreement" refers to all the terms, conditions, policies, and
        procedures that we may publish on the HinHon website from time to time.
      </li>
    </ul>
    <h3>Account Terms</h3>
    <h4>Account Registration</h4>
    <ul>
      <li>
        To access our Services, you must first register to establish an account
        by providing certain information.
      </li>
      <li>HinHon does not charge any registration fee to our users.</li>
      <li>
        Upon registration, you need to provide a valid phone number, your name,
        language, date of birth, and gender.
      </li>
    </ul>
    <h4>Account Requirements</h4>
    <ul>
      <li>You can not create more than one account.</li>
      <li>
        You must be age 17 or older to use our Services. If we acknowledge that
        the account is under the age of 17, we will terminate that account
        immediately and may restrict the use of Services in the future.
      </li>
      <li>Your login information may only be used by yourself.</li>
      <li>
        You can not use Reyo in violation of laws or any other applicable legal
        obligations.
      </li>
    </ul>
    <h4>Account Security</h4>
    <ul>
      <li>
        You are fully responsible for any activity that occurs on your account,
        including any losses incurred by HinHon or other Users resulting from
        any activity that occurs with your account.
      </li>
      <li>
        You are responsible for maintaining the security and confidentiality of
        the information of your account.
      </li>
      <li>
        HinHon will not ask for verification codes (including one-time-password
        (OTP) sent via text message) for any reason. You cannot provide data
        related to your account to any party without exception.
      </li>
      <li>
        You agree to notify us immediately of any unauthorized use of your
        account or any other breach of security.
      </li>
      <li>
        You understand and agree that Konekto will not be liable for any loss or
        damage arising from your failure to maintain the security and
        confidentiality of your account.
      </li>
    </ul>

    <h3>Acceptable Use</h3>
    <p>
      You understand and agree that the use of all of our Services must not
      violate any applicable laws. You are aware that it is your responsibility
      to make sure that your use of our Services is in compliance with laws and
      any applicable regulations.
    </p>
    <p>
      You agree not to use or attempt to use our Services for any purpose
      prohibited by these Terms of Use or any applicable laws and regulations,
      including but not limited to:
    </p>
    <ul>
      <li>
        Create and/or disseminate content that is unlawful, defamatory,
        libelous, obscene, fraudulent, predatory of minors, harasses, threatens,
        or hates any person;
      </li>
      <li>Commit acts of sexual harassment;</li>
      <li>
        Create and/or use any hardware, software, features, and/or other devices
        for the purpose of manipulating the system;
      </li>
      <li>Attempt to offend people by using our Services;</li>
      <li>
        Impersonate another person or entity, or by fraudulently misrepresenting
        your identity or site"s purpose;
      </li>
      <li>
        Violate the privacy of other users by sharing personal information;
      </li>
      <li>
        Use robots, scrapers, or any other means to access our Services for any
        purpose;
      </li>
    </ul>
    <p>
      HinHon reserves the right to terminate your account if we indicate or
      receive any report that your account violates the above conditions or any
      other law obligations.
    </p>

    <h3>Privacy</h3>
    <p>
      These Terms of Use are included and should be read in conjunction with our
      Privacy Policy. Our Privacy Policy informs you of how we process, store
      and safeguard your personal data when you use our Services. We highly
      recommend you to read our Privacy Policy in order to understand your
      privacy rights.
    </p>

    <h3>Cancellation and Termination</h3>
    <p>
      You have the option to permanently delete your account on the Platform by
      contacting our user service. You agree that we have no obligation
      whatsoever to recover your content, data, and information stored on or as
      part of your account.
    </p>
    <p>
      You should also be aware that HinHon has the right to terminate your
      account and access to all of our Services with or without notice.
    </p>

    <p>
      HinHon has the right to change, suspend, or discontinue all or part of our
      Services, either temporarily or permanently, without prior notice.
    </p>

    <h3>Intellectual Property Notice</h3>
    <p>
      All of the content available on or through our Services, including all
      software and accompanying documentation made available for download are
      the property of HinHon. You may not duplicate, copy, or reuse any portion
      of our Services, software, documentation, or content without express
      written permission from HinHon.
    </p>

    <h3>Disclaimers</h3>
    <p>
      HinHon never promises that all of our Services will meet your
      requirements. HinHon does not warrant that our Services will be secure,
      error-free, uninterrupted, or free of viruses. Our Services and all of the
      content are delivered on an "as is" and "as available" basis. All
      information provided is subject to change without notice.
    </p>
    <p>
      You assume full responsibility and risk of loss resulting from using or
      downloading any content, information, file, or other material from our
      Services.
    </p>

    <h3>Limitations of Liability</h3>
    <p>
      You expressly understand that HinHon will not be liable for any damages or
      losses from your use of our Services. Under no circumstances shall HinHon,
      our licensor, or any third party who promotes our Service or provides you
      with the link to our Services be liable in any way for your use of the
      service or any of its content.
    </p>
    <p>
      You agree that HinHon shall not be liable for any liability unless it
      arises from our intentional or gross negligence.
    </p>

    <h3>Disputes</h3>
    <p>
      If you have a dispute with one or more users, you agree to hold HinHon
      harmless from any and all claims, demands, and losses of any kind and
      nature, known or unknown, arising out of or in any way related to such
      dispute.
    </p>
    <p>
      You agree to indemnify, defend and hold harmless us from and against any
      and all claims, liabilities, and expenses, including attorneys" fees,
      arising from your use of our Services.
    </p>
    <p>
      You also agree to release, hold harmless and indemnify HinHon against any
      and all claims or demands from any third party, including reasonable legal
      fees incurred by HinHon due to your failure to comply with these Terms of
      Use.
    </p>

    <h3>Changes to These Terms</h3>
    <p>
      HinHon may change, modify, or supplement these Terms of Use from time to
      time. Whenever we make an amendment, we will address the changes in our
      website. You agree to check these Terms of Use periodically.
    </p>

    <h3>Contact Information</h3>
    <p>
      If you have any questions regarding these Terms of Use, you can contact us
      through: <br />
      Email : <a href="mailto:hello@hinhon.com">hello@hinhon.com</a>
    </p>
  </div>
</template>
<script>
export default {
  data() {},
  components: {},
  computed: {},

  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.terms {
  padding: 25px;
  color: #212121;
  padding-bottom: 100px;
  h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    letter-spacing: 0.36px;
    margin-bottom: 25px;
  }

  h3 {
    font-size: 24px;
    margin: 20px 0;
    font-weight: 500;
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 15px;
    font-size: 17px;
    letter-spacing: 0.37px;
    color: #424242;
    font-weight: 300;
    line-height: 27px;
  }

  a {
    color: #a423bd;
    margin-bottom: 15px;
    text-decoration: underline;
  }

  ul {
    padding-left: 20px;
    font-size: 17px;
    letter-spacing: 0.37px;
    color: #424242;
    font-weight: 300;
    line-height: 27px;
    margin-bottom: 15px;

    &.number {
      list-style: auto;
    }
    li {
      padding-left: 10px;
    }
  }
}
</style>