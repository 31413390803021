import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "@views/HomePage.vue";
import TermsOfUsePage from "@views/TermsOfUsePage.vue";
import PrivacyPolicyPage from "@views/PrivacyPolicyPage.vue";

import i18n from "@/plugins/i18n";
Vue.use(VueRouter);

const routes = [
  {
    path: "/:locale",
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: (to, from, next) => { 
      const locale = to.params.locale; 
      if (locale !== "en" && locale !== "vi") return next('en');
      if (i18n.locale !== locale) {
        i18n.locale = locale;
      }
      return next()
    },
    children: [
      {
        path: "",
        name: "Home",
        component: HomePage
      },
      {
        path: "terms-of-use",
        name: "TermsOfUse",
        component: TermsOfUsePage
      },
      {
        path: "privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicyPage
      },
    ],
  },
  {
    path: "*",
    redirect() {
      return "/en";
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;