import Vue from "vue";
import VueI18n from "vue-i18n" ;
import enMessage from "./en";
import viMessage from "./vi";

Vue.use(VueI18n);

const messages = {
    'en': enMessage,
    'vi': viMessage,
};

const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
});

export default i18n;