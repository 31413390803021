<template>
  <div class="landing-page">
    <div class="row">
      <div class="about-us">
        <div class="content">
          <div class="title-image">
            <img src="@assets/brand.png" title="Hinhon" alt="Hinhon" />
          </div>
          <div
            class="title"
            v-html="$t('title_using_to_connect_with_people')"
          />
          <div class="desc">
            {{ $t("content_using_to_connect_with_people") }}.
          </div>
          <div class="download-apps on-mb" style="margin-bottom: 20px">
            <div class="label">{{ $t("download_the_app") }}</div>
            <div class="app" style="padding-left: 0 !important">
              <a
                href="https://apps.apple.com/us/app/hinhon/id1593519314"
                target="_blank"
              >
                <img
                  src="@assets/app-store.png"
                  alt="App Store"
                  title="App Store"
                />
              </a>
            </div>
            <div class="app">
              <a
                href="https://play.google.com/store/apps/details?id=com.hinhon.app"
                target="_blank"
              >
                <img
                  src="@assets/google-store.png"
                  alt="Google Store"
                  title="Google Store"
                />
              </a>
            </div>
          </div>
          <div class="item-lst">
            <div class="item">
              <div class="item-title">
                <img
                  src="@assets/friend-icon.png"
                  class="icon"
                  alt="Add Favorite #"
                  title="Add Favorite #"
                />
                {{ $t("title_add_favorite") }}
              </div>
              <div class="text">
                {{ $t("content_add_favorite") }}
              </div>
            </div>
            <div class="item">
              <div class="item-title">
                <img
                  src="@assets/video-icon.png"
                  class="icon"
                  alt="Connect through Video Calls"
                  title="Connect through Video Calls"
                />
                {{ $t("title_connect_through_video_calls") }}
              </div>
              <div class="text">
                {{ $t("content_connect_through_video_calls") }}
              </div>
            </div>
            <div class="item">
              <div class="item-title">
                <img
                  src="@assets/message-icon.png"
                  class="icon"
                  alt="Get Acquainted with Your New Connections"
                  title="Get Acquainted with Your New Connections"
                />
                {{ $t("title_get_acquainted_with_your_new_connections") }}
              </div>
              <div class="text">
                {{ $t("content_get_acquainted_with_your_new_connections") }}
              </div>
            </div>
          </div>

          <div class="about-image">
            <img src="@assets/connect-with-people-through-video-call.png" />
          </div>

          <div class="download-apps">
            <div class="label">{{ $t("download_the_app") }}</div>
            <div class="app" style="padding-left: 0 !important">
              <a
                href="https://apps.apple.com/us/app/hinhon/id1593519314"
                target="_blank"
              >
                <img
                  src="@assets/app-store.png"
                  alt="App Store"
                  title="App Store"
                />
              </a>
            </div>
            <div class="app">
              <a
                href="https://play.google.com/store/apps/details?id=com.hinhon.app"
                target="_blank"
              >
                <img
                  src="@assets/google-store.png"
                  alt="Google Store"
                  title="Google Store"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {},

  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.landing-page {
  position: relative;
  overflow: hidden;
  padding-bottom: 70px;
  .row {
    &:after {
      clear: both;
      display: table;
      content: "";
    }

    .about-image {
      img {
        max-width: max-content;
      }
      @media (min-width: 200px) {
        width: 100%;
        text-align: center;
        img {
          height: auto;
          margin-top: 10px;
          max-width: 400px;
        }
      }
      @media (min-width: 767px) {
        position: absolute;
        height: 100%;
        right: 0;
        top: 20px;
        text-align: right;
        width: 400px;
        float: right;
        img {
          max-width: max-content;
          height: 50%;
          margin-top: 20%;
        }
      }

      @media (min-width: 992px) {
        width: 600px;
        img {
          height: 65%;
          margin-top: 0;
        }
      }

      @media (min-width: 1200px) {
        img {
          height: 75%;
        }
      }
    }
    .about-us {
      text-align: center;
      .content {
        width: 100%;
        max-width: 650px;
        text-align: left;
        display: inline-block;
      }
      @media (min-width: 200px) {
        .on-mb {
          display: block;
        }
      }
      @media (min-width: 767px) {
        width: calc(100% - 400px);
        .on-mb {
          display: none;
        }
        .content {
          max-width: max-content;
        }
      }

      @media (min-width: 992px) {
        width: calc(100% - 600px);
        .content {
          max-width: 650px;
        }
      }
    }
    @media (min-width: 300px) {
      padding: 0 15px;
    }

    @media (min-width: 767px) {
      padding: 0 40px;
    }
  }
  .title-image {
    margin-bottom: 40px;
    img {
      height: 35px;
    }
    @media (min-width: 300px) {
      margin-top: 30px;
    }

    @media (min-width: 900px) {
      margin-top: 50px;
    }
  }
  .title {
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    letter-spacing: 0.36px;
    color: #212121;
    margin-bottom: 25px;
    max-width: 500px;
    .colorgradient {
      display: inline-block;
      background: -webkit-linear-gradient(#f560a9, #7d06c7);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .desc {
    font-size: 17px;
    letter-spacing: 0.37px;
    color: #424242;
    font-weight: 300;
    line-height: 27px;
    p {
      margin-bottom: 15px;
    }
  }

  .item-lst {
    .item {
      position: relative;
      padding-left: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .item-title {
      font-size: 24px !important;
      margin-bottom: 10px;
      font-weight: 500;
      display: inline-block;
      background: -webkit-linear-gradient(#f560a9, #7d06c7);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      .icon {
        position: absolute;
        top: 2px;
        left: 0;
        width: 30px;
      }
    }
    .text {
      font-size: 17px;
      letter-spacing: 0.37px;
      color: #424242;
      font-weight: 300;
      line-height: 27px;
    }
  }

  .download-apps {
    margin-top: 30px;
    margin-bottom: 25px;

    @media (min-width: 200px) {
      text-align: center;
    }

    @media (min-width: 767px) {
      text-align: left;
    }

    .label {
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      color: #616161;
      margin-bottom: 10px;
    }
    .app {
      display: inline-block;
      max-width: 50%;
      padding: 10px;
    }
  }
}
</style>