<template>
  <home-page-vi v-if="lang === 'vi'" />
  <home-page-en v-else />
</template>
<script>
import HomePageVi from "@views/HomePage-vi.vue";
import HomePageEn from "@views/HomePage-en.vue";
import i18n from "@/plugins/i18n";
export default {
  data() {
    return {
      lang: i18n.locale,
    };
  },
  components: { HomePageVi, HomePageEn },
  computed: {},

  methods: {},
  created() {},
};
</script>