<template>
  <header>
    <div class="header">
      <div class="log-go-text">
        <a href="/" title="Hinhon"
          ><img src="@assets/hinhontext-logo.png" title="Hinhon" alt="Hihon"
        /></a>
      </div>
      <div class="social">
        <div>
          <p class="label">{{ $t("connect_with_us") }}:</p>
          <a href="mailto:hello@hinhon.com" title="send mail">
            <img
              src="@assets/mail-icon.png"
              title="Email"
              alt="Email"
              style="margin-left: 0"
            />
          </a>
        </div>
        <div class="languages">
          <p class="label">{{ $t("language") }}:</p>
          <div class="select">
            <select @change="changeLocale" v-model="defaulLanguege">
              <option value="en">English</option>
              <option value="vi">Tiếng Việt</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import i18n from "@/plugins/i18n";

export default {
  components: {},
  data() {
    return {
      defaulLanguege: i18n.locale,
    };
  },
  methods: {
    changeLocale(e) {
      i18n.locale = e.target.value;
      window.location.href = `/${i18n.locale}`;
    },
  },
};
</script>
<style scoped lang="scss">
header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
}
.header {
  position: relative;
  padding: 20px 25px;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 10px;
  .social {
    position: absolute;
    right: 25px;
    top: 20px;
    text-align: right;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 25px;
    align-items: start;
    .label {
      margin-top: -3px;
      margin-bottom: 7px;
      text-align: left;
      color: #5a5a5a;
    }
    span {
      font-size: 16px;
      line-height: 34px;
      text-align: right;
      letter-spacing: 0.37px;
      color: #616161;
      font-weight: 400;
    }
    img {
      margin-left: 20px;
      height: 25px;
      vertical-align: middle;
    }
  }
  @media (min-width: 300px) {
    text-align: left;
    .languages {
      display: inline-block;
      text-align: left;
      width: 120px;
      select {
        padding: 5px;
        outline: none;
        cursor: pointer;
        border-radius: 3px;
        border: 1px solid #9e9e9e;
      }
    }
  }

  .select {
    position: relative;
    &::after {
      content: "▼";
      color: #333;
      font-size: 14px;
      top: 5px;
      right: 7px;
      position: absolute;
    }
    select {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
    }
  }

  @media (min-width: 900px) {
    text-align: center;
  }
}
</style>