<template>
  <div class="landing-page">
    <div class="row">
      <div class="page-summary">
        <div class="content">
          <div class="title-image">
            <img src="@assets/brand.png" title="Hinhon" alt="Hinhon" />
          </div>
          <div
            class="title"
            v-html="$t('title_using_to_connect_with_people')"
          />
          <div class="desc">
            {{ $t("content_using_to_connect_with_people") }}.
          </div>
          <download />
        </div>
        <div class="about-image">
          <img src="@assets/connect-with-people-through-video-call.png" />
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 70px">
      <div class="row-title">
        Các <span class="colorgradient">Tính Năng</span> Nổi Bật
      </div>
      <div class="features-lst">
        <div class="item">
          <div class="item-title">
            <img
              src="@assets/friend-icon.png"
              class="icon"
              alt="Add Favorite #"
              title="Add Favorite #"
            />

            <span>{{ $t("title_add_favorite") }}</span>
          </div>
          <div class="text">
            {{ $t("content_add_favorite") }}
          </div>
        </div>
        <div class="item">
          <div class="item-title">
            <img
              src="@assets/video-icon.png"
              class="icon"
              alt="Connect through Video Calls"
              title="Connect through Video Calls"
            />
            <span>{{ $t("title_connect_through_video_calls") }}</span>
          </div>
          <div class="text">
            {{ $t("content_connect_through_video_calls") }}
          </div>
        </div>
        <div class="item">
          <div class="item-title">
            <img
              src="@assets/message-icon.png"
              class="icon"
              alt="Get Acquainted with Your New Connections"
              title="Get Acquainted with Your New Connections"
            />
            <span>{{
              $t("title_get_acquainted_with_your_new_connections")
            }}</span>
          </div>
          <div class="text">
            {{ $t("content_get_acquainted_with_your_new_connections") }}
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="background: #212121; padding: 40px">
      <div class="row-title" style="color: #fff">
        Người Dùng <span class="colorgradient">HinHon</span> Nói Gì
      </div>
      <div class="reviews">
        <div class="list">
          <p>
            “Mình không tin vào kiểu tìm bạn trên mạng và cũng chưa bao giờ tham
            gia các ứng dụng hẹn hò. Đối với mình, HinHon như một cộng đồng để
            mình tìm bạn bè cùng sở thích và tám với nhau mỗi ngày về niềm đam
            mê của mình. Ngay cả bạn bè của mình cũng đôi khi không hiểu được
            thú vui đó! ”
          </p>
          <p>
            “Rào cản lớn nhất để tôi tham gia các ứng dụng hẹn hò là nội dung ảo
            và các profile không có thật, mất thời gian và ảnh hưởng đến trải
            nghiệm. Tôi cảm thấy tự tin hơn khi được tiếp xúc với người thật bên
            kia màn hình và video call. Chính sách chặn người dùng quấy rối
            thông qua cơ chế report cũng làm tôi yên tâm hơn”
          </p>
          <p>
            “Mình kiểu đi làm mỗi ngày khá mệt đến nỗi tối không thể ra ngoài
            hay đi chơi với bạn bè được nữa. Ở nhà mãi thì buồn. Mình dùng
            HinHon giống như cách lướt Tiktok mỗi ngày, được cái là có thể tương
            tác với người bên kia màn hình nên trải nghiệm 2 chiều và thật hơn!”
          </p>
        </div>
        <download style="text-align: center; color: #fff" />
      </div>
    </div>

    <div class="row" style="margin-top: 50px">
      <div class="about-us">
        <div class="about-us-image"><img src="@assets/about-us.png" /></div>
        <div class="about-us-content">
          <h2>Về <span class="colorgradient">HinHon</span></h2>
          <p>
            Chúng tôi bắt đầu ý tưởng này khi đang ngồi ngáp ngắn ngáp dài vào
            tháng thứ 8 làm việc tại nhà. Dịch bệnh đã thay đổi rất nhiều thứ:
            xáo trộn quy tắc làm việc, phá vỡ các mối quan hệ, đe dọa nếp sinh
            hoạt hàng ngày. Chúng ta thật may mắn khi đã sống sót, nhưng đã thực
            sự “sống” chưa?
          </p>

          <p>
            Với câu hỏi này, chúng tôi tự đặt ra thử thách cho bản thân: làm thế
            nào để hòa nhập với cuộc sống mới, khi ranh giới giữa offline và
            online ngày càng gần nhau? Những hoạt động vốn dĩ phải bắt buộc ra
            đường như mua sắm, đi làm, thậm chí là hẹn hò đàn đúm… nay đã được
            trực tuyến hóa. Liệu trải nghiệm có được trọn vẹn như trước? Và
            HinHon ra đời…
          </p>
          <p>
            Đây là ứng dụng tìm bạn bè, tìm một cuộc nói chuyện, tìm một ai
            đó…bất cứ ai! Để chúng ta không cô đơn và để mỗi ngày trôi qua đều
            thật ý nghĩa với những người bạn đồng hành bên cạnh.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Download from "@views/download.vue";
export default {
  components: { Download },
  data() {
    return {};
  },
  computed: {},

  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.landing-page {
  position: relative;
  overflow: hidden;
  padding-bottom: 70px;
  .row {
    &:after {
      clear: both;
      display: table;
      content: "";
    }

    .row-title {
      text-align: center;
      font-weight: 700;
      font-size: 32px;
      margin-bottom: 30px;
    }

    .about-image {
      img {
        max-width: max-content;
      }
      @media (min-width: 200px) {
        display: none;
      }
      @media (min-width: 992px) {
        display: block;
        position: absolute;
        height: 100%;
        right: -40px;
        top: 0;
        text-align: right;
        img {
          max-width: max-content;
          height: 100%;
        }
      }
    }
    .page-summary {
      text-align: center;
      position: relative;
      .content {
        width: 100%;
        text-align: left;
        display: inline-block;
      }
      @media (min-width: 200px) {
        .on-mb {
          display: block;
        }
      }
      @media (min-width: 767px) {
        .on-mb {
          display: none;
        }
        .content {
          max-width: max-content;
        }
      }

      @media (min-width: 992px) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @media (min-width: 1200px) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        padding-left: 150px;
      }
    }
    @media (min-width: 300px) {
      padding: 0 15px;
    }

    @media (min-width: 767px) {
      padding: 0 40px;
    }
  }
  .title-image {
    margin-bottom: 40px;
    img {
      height: 35px;
    }
    @media (min-width: 300px) {
      margin-top: 30px;
    }

    @media (min-width: 900px) {
      margin-top: 50px;
    }
  }
  .title {
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    letter-spacing: 0.36px;
    color: #212121;
    margin-bottom: 25px;
    max-width: 100%;
    .colorgradient {
      display: inline-block;
      background: -webkit-linear-gradient(#f560a9, #7d06c7);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .desc {
    font-size: 17px;
    letter-spacing: 0.37px;
    color: #424242;
    font-weight: 300;
    line-height: 27px;
    p {
      margin-bottom: 15px;
    }
  }

  .features-lst {
    .item {
      position: relative;
      padding-left: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .item-title {
      font-size: 24px !important;
      margin-bottom: 10px;
      font-weight: 500;
      background: -webkit-linear-gradient(#f560a9, #7d06c7);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      .icon {
        display: inline-block;
      }
    }
    .text {
      font-size: 17px;
      letter-spacing: 0.37px;
      color: #424242;
      font-weight: 300;
      line-height: 27px;
    }

    display: grid;

    @media (min-width: 300px) {
      .item-title {
        display: inline-block;
        text-align: left;
        .icon {
          position: absolute;
          top: 2px;
          left: 0;
          width: 30px;
          height: auto;
          margin-bottom: 20px;
        }
      }
    }
    @media (min-width: 992px) {
      grid-gap: 20px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      .item-title {
        display: block;
        text-align: center;
        span {
          display: block;
        }
        .icon {
          position: relative;
          width: 50px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .reviews {
    padding: 20px 0;
    .list {
      display: grid;
      grid-gap: 40px;
      p {
        color: #fafafa;
        font-size: 14px;
        font-style: italic;
      }

      @media (min-width: 300px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
      @media (min-width: 767px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }
  .about-us {
    padding: 40px 0;
    display: grid;
    grid-gap: 10px;
    .about-us-image {
      img {
        width: 90%;
      }
    }
    .about-us-content {
      h2 {
        margin-bottom: 10px;
      }
      p {
        margin: 15px 0;
      }
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #424242;
    }

    @media (min-width: 300px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      .about-us-image {
        margin-bottom: 30px;
      }
    }
    @media (min-width: 1000px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      .about-us-image {
        margin-bottom: 0;
      }
    }
  }
}
</style>