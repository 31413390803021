<template>
  <div style="min-height: 100%; position: relative">
    <the-header />
    <main>
      <slot />
    </main>
    <the-footer />
  </div>
</template>

<script>
import TheHeader from "@layouts/TheHeader";
import TheFooter from "@layouts/TheFooter";
export default {
  components: {
    TheHeader,
    TheFooter
  }
};
</script>

<style scoped lang="scss">
</style>